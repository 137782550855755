::-webkit-scrollbar {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.4);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.8);
}

::-webkit-scrollbar {
  display: none;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}


h1 {
  font-size: 200px;
}
h2 {
  font-size: 35px;
}
h4 {
  font-size: 22px;
}

p,
label,
input {
  font-size: 14px;
}
span {
  font-size: 14px;
}

button {
  cursor: pointer;
  border: 1px solid #c1c1c1;
}

select,
input {
  border-color: transparent;
  background-color: transparent;
}

input:focus {
  border-color: dodgerblue;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}

img {
  max-height: 100%;
  max-width: 100%;
}
.dropdown {
  /* z-index: -1; */
  position: absolute;
  right: 20px;
  bottom: 15px;
  max-height: 14px;
  max-width: 14px;
}
select {
  background-color: transparent;
}
.field {
  background-color: transparent;
  z-index: 1;
}

.swellOnLoad {
  transform: scale(1.2);
}
html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  background-color: rgba(12, 58, 93, 1);
  scrollbar-width: none; /* Firefox 64 */
}