html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-family: "roboto"; */
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* background-color: rgba(12, 58, 93, 1); */
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  /* background-color: rgba(12, 58, 93, 1); */
}
*,
*:before,
*:after {
  box-sizing: border-box;
  box-sizing: inherit;
}
body {
  height: 100%;
  line-height: 1.4;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
  scrollbar-width: none !important;  /* Firefox 64 testing */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  /* text-decoration: none; */
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input,
textarea,
select {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  color: black;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  box-shadow: none;
  outline: none;
  appearance: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-touch-callout: none;

  /* -webkit-user-select: none; */
  /* -khtml-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

input {
  text-align: left;
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 1; /* Firefox */
}

@media print {
  a[href]::after {
    content: none !important;
  }
}
::-webkit-scrollbar {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.4);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.8);
}

::-webkit-scrollbar {
  display: none;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}


h1 {
  font-size: 200px;
}
h2 {
  font-size: 35px;
}
h4 {
  font-size: 22px;
}

p,
label,
input {
  font-size: 14px;
}
span {
  font-size: 14px;
}

button {
  cursor: pointer;
  border: 1px solid #c1c1c1;
}

select,
input {
  border-color: transparent;
  background-color: transparent;
}

input:focus {
  border-color: dodgerblue;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}

img {
  max-height: 100%;
  max-width: 100%;
}
.dropdown {
  /* z-index: -1; */
  position: absolute;
  right: 20px;
  bottom: 15px;
  max-height: 14px;
  max-width: 14px;
}
select {
  background-color: transparent;
}
.field {
  background-color: transparent;
  z-index: 1;
}

.swellOnLoad {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  background-color: rgba(12, 58, 93, 1);
  scrollbar-width: none; /* Firefox 64 */
}
